import React, { useContext } from 'react';
import context from '../../context';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Section, Button } from '../../styled-components';
import Link from '../link';
import logoImg from '../../images/logo-light.png';
import WhyImg from '../../images/brand-icon-half.png';
import Why from '../home/why-2';

const SectionCustom = styled(Section)`

`
const Logo = styled.img`
  width: 300px;
  margin-bottom: 2rem;
  @media(min-width: 768px){

  }
`
const Image = styled.img`
  width: 100%;
  height: 50vh;
  object-fit: cover;
  object-position: center;
  margin-bottom: 2rem;
  @media(min-width: 768px){
    height: 100%;
  }
`
const InfoCont = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Title = styled.h2`
  //width: 50%;
  color: ${props => props.theme.primaryColor};
  margin: 2rem 0;
`
const Description = styled.div`
  p{
    margin-bottom: 2rem
  }
`
const Bold = styled.span`
  font-weight: bold;
`
const Red = styled.span`
  color: ${props => props.theme.primaryColor};  
`
const Paragraph = styled.p`
  width: 70%;
  text-align: center;
`

const WhyImage = styled.div`
  background-image: url("${WhyImg}");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: .3;
  height: 100%;
`
const WhyCont = styled.div`
  margin: 6rem 0;
`
const WhyInfoCont = styled.div`
  
`
const WhyTitle = styled(Title)`
  text-align: left;
  margin-bottom: 2rem;
`
const WhySubTitle = styled(Bold)`
  border-bottom: 2px solid hsl(3, 55%, 85%);
  line-height: 2rem;
  text-transform: uppercase;
`
const WhyParagraph = styled(Paragraph)`
  text-align: left;
  margin-bottom: 2rem;
`
const WhyList = styled.ol`
  font-size: .8rem;
  list-style: none;
`
const WhyItem = styled.li`

`
const ContactCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const ContactTitle = styled.h3`

`
const ContactSubTitle = styled.p`
  font-size: 1.2rem;
  margin: 1.5rem 0;
`

export default ()=> {
  const state = useContext(context);
  return(
    <SectionCustom>
      <Container>
        <Row>
          <Col xs={12} md={{ span: 7, order: 2 }}>
            <Image alt="historia" src={state.about.history.background} />
          </Col>
          <Col xs={12} md={{ span: 5, order: 1 }}>
            <InfoCont>
              <Logo src={logoImg} />
            {/* <Title>
              {state.about.history.title}
            </Title> */}
            <Description>
              <p>
              Con más de 17 años de experiencia, Ambiado Propiedades se ha posicionado como un aliado confiable en el mercado inmobiliario. Hemos consolidado nuestra reputación al firmar exitosamente más de 6000 contratos, cada uno de ellos representa una historia de éxito y satisfacción del cliente.
              </p>
              <p>Nos enorgullecemos de nuestras alianzas estratégicas, las cuales garantizan un servicio integral y de la más alta calidad. Esta combinación de experiencia, confiabilidad y colaboraciones estratégicas nos permite ofrecerte un enfoque diferenciado en el corretaje inmobiliario.
              
              </p>
              <p>
              Ambiado Propiedades no solo se trata de vender propiedades, se trata de brindar una experiencia inigualable en el proceso de venta. <span style={{ fontWeight: "bold" }}>Confía en nosotros para ser tu socio de confianza en el mundo inmobiliario.</span>
              </p>
            </Description>
            </InfoCont>
          </Col>          
        </Row>     
        <div style={{ marginTop: "6rem" }}>
          <Why />    
        </div>
        <ContactCont>
          <ContactTitle>
            ¿Tienes alguna duda?
          </ContactTitle>
          <ContactSubTitle>
            Llámanos o escríbenos, un agente estará encantado de asistir tu proceso
          </ContactSubTitle>
          <Link to="/contact">
            <Button primary>
              Contacto
            </Button>
          </Link>
        </ContactCont>
      </Container>
    </SectionCustom>
  )
}